import React, {Component} from "react";
import {addField} from 'ra-core';
import SearchBox from './SearchBox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Parse from "parse";

class Address extends Component {
    constructor(props) {
        super(props);
        this.state = {
            geopoint: {lng: 44.503490, lat: 40.177200},
            valueText: ""
        };

        this.putMarkerFromSearch = (event) => {
            console.log("putMarkerFromSearch", event)
            var geopoint = event.markerPos;
            this.setState({geopoint: geopoint}, () => {
                const {input} = this.props;
                return input.onChange(this.state.geopoint);
            });
        }


    }

    componentDidMount() {
        //  console.log("source", Parse.User.current().get("defaultTravelPickupAddress"))


        switch (this.props.source) {
            case "pickupPoint":
                if (Parse.User.current() && Parse.User.current().get("defaultTravelPickupGeo")) {
                    var geopoint = {lng: 40, lat: 40};
                    geopoint.lng = Parse.User.current().get("defaultTravelPickupGeo").longitude;
                    geopoint.lat = Parse.User.current().get("defaultTravelPickupGeo").latitude;
                    localStorage.setItem('pickupAddress', Parse.User.current().get("defaultTravelPickupAddress"));
                    localStorage.setItem('destinationAddress', "");
                    this.setState({geopoint: geopoint, valueText: Parse.User.current().get("defaultTravelPickupAddress")}, () => {
                        const {input} = this.props;
                        return input.onChange(this.state.geopoint);
                    });
                }
                break;

            case "destinationPoint":

                break;

            default:
                break;
        }

    }

    render() {
        // const { translate } = this.props;
        return (
            <div class="First-Search">
                <SearchBox
                    putMarker={event => this.putMarkerFromSearch(event)}
                    /*      deleteMarker={this.deleteMarker} */
                    /*    markers={this.state.geopoint} */
                    loadingElement={<div style={{position: "inherit !important"}}/>}
                    containerElement={<div style={{position: "inherit !important"}}/>}
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyAGSWePoezlzdHw8mR0nr5vc6LoBEiySjE&v=3.exp&libraries=geometry,drawing,places`}
                    text={this.props.text}
                    addressType={this.props.addressType}
                    valueText={this.state.valueText}
                />
            </div>
        );
    }
}

export const AddressInput = addField(Address);