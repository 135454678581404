import React, {Component} from "react";
import {addField} from 'ra-core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import '../Style/main.css'

class CarType extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCarType: "GP0o4wucA2"
        };

        this.handleChange = (event) => {
            var eventValue = event.target.value
            this.setState({selectedCarType: eventValue}, () => {
                const {input} = this.props;
                return input.onChange(this.state.selectedCarType);
            });
        }

    }

    componentDidMount() {

        const {input} = this.props;
        return input.onChange("GP0o4wucA2");

    }

    render() {
        // const { translate } = this.props;
        const locale = localStorage.getItem("locale")
        return (
            <div>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.selectedCarType}
                    onChange={this.handleChange}
                >
                    <MenuItem value={"3dQUgdHzjc"}>
                        {(locale === "ge") ? ' bis 8 Pers.' :
                            (locale === "en") ? 'up to 8 pax' :
                                ""}
                    </MenuItem>
                    <MenuItem value={"JoY9pCxaze"}>
                        {(locale === "ge") ? ' bis 6 Pers.' :
                            (locale === "en") ? 'up to 6 pax' :
                                ""}
                    </MenuItem>
                    <MenuItem value={"GP0o4wucA2"}>
                        {(locale === "ge") ? ' bis 4 Pers.' :
                            (locale === "en") ? 'up to 4 pax' :
                                ""}
                    </MenuItem>
                </Select>
            </div>
        );
    }
}

export const CarTypeInput = addField(CarType);