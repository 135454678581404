import React, {Component} from 'react';
import {withScriptjs} from 'react-google-maps';
import {StandaloneSearchBox} from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import TextField from '@material-ui/core/TextField';


class SearchBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueText: "",
            isFirstTime: true,
            isValueTextExists: false
        };
        this.input = React.createRef();

        //if(firstTimeChangeComplaint){fix by timer(1s) inside constructor by isFirstTime and isValueTextExists booleans change}
        this.onPlacesChanged = () => {
            const {input, multipleMarkers, putMarker} = this.props;
            const places = this.input.current.getPlaces();


            switch (this.props.addressType) {
                case "destination":
                    localStorage.setItem('destinationAddress', places[0].formatted_address);
                    break;

                case "pickup":
                    localStorage.setItem('pickupAddress', places[0].formatted_address);
                    break;

                default:
                    break;
            }


            //this.setState({ valueText: this.props.value })
            const markerPos = {
                lat: places[0].geometry.location.lat(),
                lng: places[0].geometry.location.lng(),
            };
            this.setState({isFirstTime: false});
            putMarker({markerPos, input, multipleMarkers});
        };

        this.handleChange = (event) => {
            this.setState({valueText: event.target.value, isFirstTime: false});
        }

        this.defaultSearchStyles = {
            boxSizing: 'border-box',
            border: '1px solid transparent',
            width: '240px',
            height: '32px',
            padding: '0 12px',
            borderRadius: '3px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            fontSize: '14px',
            outline: 'none',
            textOverflow: 'ellipses',
            marginBottom: '15px',
            position: 'absolute',
            top: '15px',
            left: '0px',
            right: '0px',
            margin: '0 auto',
            zIndex: '1',
        };
    }

    componentDidMount() {
        if (this.props.valueText) {
            this.setState({valueText: this.props.valueText, isValueTextExists: true});
        }
    }

    render() {


        const {props, onPlacesChanged, input} = this;


        return (
            <div data-standalone-searchbox="">
                <StandaloneSearchBox
                    ref={input}
                    bounds={props.bounds}
                    onPlacesChanged={onPlacesChanged}
                    addressType={props.addressType}
                >
                    {/* <SocketField  /> */}

                    {
                        (this.state.isFirstTime && this.state.isValueTextExists) ? <TextField label={props.text} value={this.state.valueText} style={{marginRight: "15px !important"}} onChange={this.handleChange}/>
                            :
                            <TextField label={props.text} style={{marginRight: "15px !important"}} onChange={this.handleChange} id=""/>
                    }

                </StandaloneSearchBox>
            </div>
        );
    }
}

const WithScriptSearchBox = withScriptjs(props => <SearchBox {...props} />);

export default WithScriptSearchBox;