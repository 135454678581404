import io from "socket.io-client";
import DistanceMatrix from 'node-distance-matrix';
import {Client} from "@googlemaps/google-maps-services-js";

const locale = localStorage.getItem("locale") || "en";
const messages = {
    ge: {
        destination_address: 'Zieladresse',
        driver_name: "Fahrername",
        travel_id: "Reise-ID",
        taxi_number: 'Taxi number',
        requestNotConfirmed: 'Anfrage nicht bestätigt',
        cancel_order: "Bestellung stornieren",
        start_time: "Start time",
        finish_time: "Finish time",
        car_number: "Car number"
    },
    en: {
        destination_address: 'Destination address',
        driver_name: "Driver Name",
        travel_id: "Travel ID",
        taxi_number: 'Taxinummer',
        requestNotConfirmed: 'Request not confirmed',
        cancel_order: "Cancel order",
        start_time: "Startzeit",
        finish_time: "Endzeit",
        car_number: "Autonummer"
    }
}


let socket;
socket = io("https://moyn.app:3000/", {
    query: {token: localStorage.getItem('sessionToken')},//localStorage.getItem('sessionToken')
    forceNew: true,
    skipreconnect: true
});

socket.on('error', function (error) {
    console.log(error);
    if (error.type === 'UnauthorizedError' || error.code === 'invalid_token') {
        /*   app.gotoLoginPage(); */
    }
    if (!isNaN(error)) {
        /*     app.showErrorMessageForId(error);
            app.gotoLoginPage(); */
    }
}.bind(this));
socket.on('disconnect', function () {
    console.log('disconnected');
});

socket.on('connect', function () {
    //socket connection log
    //console.log('connected');
    socket.emit('join', localStorage.getItem('currentUserId'));
});


socket.on("driverCanceled", function (travel) {//drive name, travel code, ok
    console.log("INFO: driverCanceled");
    document.getElementById("popup_box").innerHTML = '<div><table>' +
        '<tr>' +
        '<td rowspan="3"><img src="' + travel.driver_id.image.address.url + '" alt="busy Driver Image" height="100px" /></td>' +
        '<td colspan="2">' + travel.driver_id.first_name + " " + travel.driver_id.last_name + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' + messages[locale].travel_id + '</td>' +
        '<td>' + travel.objectId + '</td>' +
        '</tr>' +
        '</table>' +
        '<div class="btns">' +
        '<a href="." class="btn1">OK</a>' +
        '</div>' +
        '</div>';
    document.getElementById("popup_box").style.display = "block";
    //alert("Driver canceled order");
    // setTimeout(function () { window.location.reload(); }, 1000);
})

socket.on("travelStarted", function (travel) {//drive name, travel code, ok, destination address
    console.log("INFO: travelStarted");
    document.getElementById("popup_box").innerHTML = '<div><table>' +
        '<tr>' +
        '<td>' + messages[locale].driver_name + '</td>' +
        '<td>' + travel.driver_id.first_name + " " + travel.driver_id.last_name + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' + messages[locale].travel_id + '</td>' +
        '<td>' + travel.objectId + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' + messages[locale].destination_address + '</td>' +
        '<td>' + travel.destination_address + '</td>' +
        '</tr>' +
        '</table>' +
        '<div class="btns">' +
        '<a href="." class="btn1">OK</a>' +
        '</div>' +
        '</div>';
    document.getElementById("popup_box").style.display = "block";
    //alert("Travel started․ You can track the car on the map");
    //window.location.reload();
})

socket.on("travelFinished", function (travel) { //drive name, travel code, destionation, start and end time

    console.log("INFO: travelFinished");
    document.getElementById("popup_box").innerHTML = '<div><table>' +
        '<tr>' +
        '<td>' + messages[locale].driver_name + '</td>' +
        '<td>' + travel.driver_id.first_name + " " + travel.driver_id.last_name + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' + messages[locale].travel_id + '</td>' +
        '<td>' + travel.objectId + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' + messages[locale].destination_address + '</td>' +
        '<td>' + travel.destination_address + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' + messages[locale].start_time + '</td>' +
        '<td>' + travel.start_timestamp.getHours() + ":" + travel.start_timestamp.getMinutes() + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' + messages[locale].finish_time + '</td>' +
        '<td>' + travel.finish_timestamp.getHours() + ":" + travel.finish_timestamp.getMinutes() + '</td>' +
        '</tr>' +
        '</table>' +
        '<div class="btns">' +
        '<a href="." class="btn1">OK</a>' +
        '</div>' +
        '</div>';
    document.getElementById("popup_box").style.display = "block";
    //alert("Travel finished");
    //window.location.reload();
})

socket.on("driverAccepted", function (travel) {
    console.log("INFO: driverAccepted");
    document.getElementById("popup_box").innerHTML = '<div><table>' +
        '<tr>' +
        '<td rowspan="3"><img src="' + travel.driver_id.image.address.url + '" alt="busy Driver Image" height="100px" /></td>' +
        '<td colspan="2">' + travel.driver_id.first_name + " " + travel.driver_id.last_name + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' + messages[locale].taxi_number + '</td>' +
        '<td>' + travel.driver_id.car_id.taxiNumber + '</td>' +
        '</tr>' +
        '<tr>' +
        '<td>' + messages[locale].car_number + '</td>' +
        '<td>' + travel.driver_id.car_id.carNumber + '</td>' +
        '</tr>' +
        '</table>' +
        '<div class="btns">' +

        '<button onclick="cancelOrder(\'' + travel.objectId + '\')" class="btn2">' + messages[locale].cancel_order + '</button>' +
        '<a href="." class="btn1">OK</a>' +
        '</div>' +
        '</div>';
    document.getElementById("popup_box").style.display = "block";
    // alert("Driver accepted");
    // setTimeout(function () { window.location.reload(); }, 1000);
})

socket.on("requestNotConfirmed", function () {
    console.log("INFO: requestNotConfirmed");

    document.getElementById("popup_box").innerHTML = '<h1>' + messages[locale].requestNotConfirmed + '</h1><div class="btns"><a href="." class="btn1">OK</a></div>'
    document.getElementById("popup_box").style.display = "block";
    /*  alert("Request not confirmed"); */
    /*   setTimeout(function () { window.location.reload(); }, 1000); */
})
socket.connect();

function getDriversFromSocket(driverIds) {
    console.log("getDriversFromSocket")
    var markers = [{lng: 44.515490, lat: 40.179200, showInfoWindow: false, driverId: ""},
        {lng: 44.500490, lat: 40.177200, showInfoWindow: false, driverId: ""}];
    socket.emit("getDriversLocationsByIds", driverIds, (answercode, result) => {
        console.log("locations from api", result);
        result.forEach(element => {
            markers.push(element)
        });
        console.log("markers inside emit", markers);

    });
    return markers;
}

export function getArrivalTime(originPoint, destinationPoint) {
    let arrivalTime = "test OK " + originPoint
    const origin = {
        lat: 40.13641870000001,
        lng: 45.3023323
    }

    const destination = {
        lat: 40.3545295,
        lng: 40.3545295
    }

    const client = new Client({});

    client
        .elevation({
            params: {
                locations: [{lat: 45, lng: -110}],
                key: "AIzaSyAMUQvflhiCHNmXfZi36Ckm2eYUpdsodCg",
            },
            timeout: 1000, // milliseconds
        })
        .then((r) => {
            console.log(r.data.results[0].elevation);
        })
        .catch((e) => {
            console.log(e);
        });


    return arrivalTime
}

export {getDriversFromSocket, socket};