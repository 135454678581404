import React, {Component} from "react";

import {addField} from 'ra-core';

import MenuItem from '@material-ui/core/MenuItem';

import Select from '@material-ui/core/Select';

import Parse from "parse";

import io from "socket.io-client";


class ActiveDrivers extends Component {

    constructor(props) {

        super(props);

        this.state = {

            approvedDrivers: [],

            selectedDriverId: ""//RTAy7WtD8R"//Levon Mirakyan-i Id-n e @ntrvac

        };

        this.handleChange = (event) => {

            const eventValue = event.target.value;

            console.log("selectedDriverId", eventValue);

            this.setState({selectedDriverId: eventValue}, () => {

                const {input} = this.props;

                return input.onChange(this.state.selectedDriverId);

            });


            // this.setState({ selectedDriverId: selectedDriverId }, async () => {

            //     var travelQuery = new Parse.Query("travel");

            //     const travel = await travelQuery.get(props.record.id);

            //     const oldDriverId = travel.get("driver_id").id

            //     console.log("oldDriverId", oldDriverId)

            //     travel.set("driver_id", { "__type": "Pointer", "className": "_User", "objectId": selectedDriverId })

            //     try {

            //         socket.emit("driverChanged", oldDriverId, selectedDriverId);

            //         await travel.save();

            //         window.location.reload();

            //     }

            //     catch (error) {

            //         console.log("error", error)

            //     }

            // })

        }

    }

    componentDidMount() {

        //  console.log("this.props.record", this.props.record.status.id)

        var driversQuery = new Parse.Query("User");

        driversQuery.equalTo("is_driver_registered", true)

        driversQuery.find().then((resultDrivers) => {

            this.setState({approvedDrivers: resultDrivers})

        })

        // this.setState({  valueText: Parse.User.current().get("defaultTravelPickupAddress") }

        try {

            if (this.props.record[this.props.source]) {

                //var selectedDriverId = this.props.record[this.props.source].id;

                //this.setState({ selectedDriverId: selectedDriverId });

            } else {

            }

        } catch {
        }

        const {input} = this.props;

        return input.onChange("RTAy7WtD8R");

    }

    render() {

        // const { translate } = this.props;

        return (

            <select className="selectdriver"

                    value={this.state.selectedDriverId}

                    onChange={this.handleChange}

                    selected={this.state.selectedDriverId}

            >

                <option style={{color: "red"}} value={''}>{'Select Your Favorite Driver'}</option>

                {this.state.approvedDrivers.map(driver => {

                    return (

                        <option value={driver.id}>{driver.get("first_name")} {driver.get("last_name")}{/*{"barev"}*/}</option>

                    )

                })}

            </select>

        );

    }

}

export const ActiveDriversInput = addField(ActiveDrivers);