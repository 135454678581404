import {
    GET_LIST,
    GET_ONE,
    GET_MANY,
    GET_MANY_REFERENCE,
    CREATE,
    UPDATE,
    UPDATE_MANY,
    DELETE,
    DELETE_MANY
} from "react-admin";

import Parse from "parse";

var parseC;
export default parseC = ({URL, APP_ID, JAVASCRIPT_KEY}) => {
    if (Parse.applicationId == null || Parse.javaScriptKey == null) {
        Parse.initialize(APP_ID, JAVASCRIPT_KEY);
        Parse.serverURL = URL;
    }
    return async (type, resource, params) => {


        const resourceObj = Parse.Object.extend(resource);
        const query = new Parse.Query(resourceObj);

        /*    console.log("type===============>", type); */

        switch (type) {
            case GET_LIST: {

                try {
                    const {page, perPage} = params.pagination;
                    const {field, order} = params.sort;
                    const {filter} = params;
                    query.limit(perPage);
                    query.skip((page - 1) * perPage);

                    // console.log("resource", resource)
                    switch (resource) {
                        case "travel":
                            /*  query.equalTo("rider_id", { "__type": "Pointer", "className": "_User", "objectId": Parse.User.current().id }) */
                            query.include("request_car_type");
                            query.include("status");
                            query.include("driver_id");
                            query.equalTo("rider_id", {"__type": "Pointer", "className": "_User", "objectId": Parse.User.current().id})

                            if (order === "DESC") query.descending(field);
                            else if (order === "ASEC") query.ascending(field);
                            query.descending("createdAt");
                            Object.keys(filter).map(f => query.matches(f, filter[f], 'i'));
                            const results = await query.find();
                            const count = await query.count();

                            return {
                                total: count,
                                data: results.map(o => ({id: o.id, ...o.attributes}))
                            };
                            break;
                        case "activeTravels":

                            const resourceObjActive = Parse.Object.extend("travel");
                            const queryActive = new Parse.Query(resourceObjActive);
                            queryActive.equalTo("rider_id", {"__type": "Pointer", "className": "_User", "objectId": Parse.User.current().id});
                            queryActive.include("request_car_type");
                            queryActive.include("driver_id");
                            queryActive.include("status");
                            //Requested, Requested for later, Travel started, Driver accepted for lated
                            var orderStatuses = ["YF2Alb9IPF", "KzuuMNyMTq", "7jSeuVPShE", "nIIDesE9Fw", "qjX0NcHGJf"];

                            queryActive.containedIn("status", orderStatuses);


                            queryActive.limit(perPage);
                            queryActive.skip((page - 1) * perPage);
                            if (order === "DESC") queryActive.descending(field);
                            else if (order === "ASEC") queryActive.ascending(field);
                            Object.keys(filter).map(f => queryActive.matches(f, filter[f], 'i'));
                            var queryActiveCount = await queryActive.count();
                            if (queryActiveCount === 0) {//for show create buttons and map
                                queryActiveCount += 1;
                            }
                            const resultsActive = await queryActive.find();
                            return {
                                total: queryActiveCount,
                                data: resultsActive.map(o => ({id: o.id, ...o.attributes}))
                            };
                            break;


                        default:
                            break;
                    }


                } catch (error) {
                    console.log("error", error)
                }


            }
            case GET_ONE: {
                const result = await query.get(params.id);
                return {
                    data: {id: result.id, ...result.attributes}
                };
            }
            case GET_MANY: {

                const results = params.ids.map(id => (new Parse.Query(resourceObj)).get(id));
                const data = await Promise.all(results);
                return {
                    total: data.length,
                    data: data.map(o => ({id: o.id, ...o.attributes}))
                };

            }
            case GET_MANY_REFERENCE: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                query.equalTo(params.target, params.id);
                const count = await query.count();
                query.limit(perPage);
                query.skip((page - 1) * perPage);
                if (order === "DESC") query.descending(field);
                else if (order === "ASEC") query.ascending(field);

                const results = await query.find();
                return {
                    total: count,
                    data: results.map(o => ({id: o.id, ...o.attributes}))
                };

            }
            case CREATE: {


                console.log("params.data", params.data);
                switch (resource) {
                    case "activeTravels":
                        resource = "travel";
                        const resourceObj = Parse.Object.extend(resource);
                        const resObj = new resourceObj();

                        resObj.set("rider_id", {"__type": "Pointer", "className": "_User", "objectId": Parse.User.current().id})

                        if (params.data['pickupPoint']) {
                            const geoObj = Parse.Object.extend("get_point");
                            const geoPointObj = new geoObj();
                            var pickupGeopint = new Parse.GeoPoint({latitude: params.data['pickupPoint'].lat, longitude: params.data['pickupPoint'].lng});
                            geoPointObj.set("geopoint", pickupGeopint);
                            resObj.set("pickup_point", geoPointObj)
                            delete params.data['pickupPoint'];
                        }

                        resObj.set("status", {"__type": "Pointer", "className": "travel_statuses", "objectId": "YF2Alb9IPF"});
                        resObj.set("isLater", false);
                        resObj.set("online_payment", false);


                        resObj.set("destination_address", localStorage.getItem('destinationAddress'));
                        resObj.set("pickup_address", localStorage.getItem('pickupAddress'));

                        var requestDate = new Date();
                        resObj.set("request_time", requestDate);

                        if (params.data['destinationPoint']) {
                            const geoObj = Parse.Object.extend("get_point");
                            const geoPointObj = new geoObj();
                            var destinationGeopint = new Parse.GeoPoint({latitude: params.data['destinationPoint'].lat, longitude: params.data['destinationPoint'].lng});
                            geoPointObj.set("geopoint", destinationGeopint);
                            resObj.set("destination_point", geoPointObj)
                            delete params.data['destinationPoint'];
                        }


                        if (params.data['carType']) {
                            var carType = "GP0o4wucA2"
                            if (params.data['carType']) {
                                carType = params.data['carType'];
                            }
                            resObj.set("request_car_type", {"__type": "Pointer", "className": "car_types", "objectId": carType})


                            delete params.data['carType'];
                        } else {
                            resObj.set("request_car_type", {"__type": "Pointer", "className": "car_types", "objectId": "GP0o4wucA2"})
                        }

                        if (params.data['isLaterAndDate']) {

                            resObj.set("isLater", params.data['isLaterAndDate'].isLater);
                            if (params.data['isLaterAndDate'].isLater) {

                                var laterDateTime = params.data['isLaterAndDate'].laterDateTime;
                                console.log("laterDateTime", laterDateTime);

                                var currentDateTime = new Date();

                                if (laterDateTime > currentDateTime.getTime()) {
                                    resObj.set("request_time", new Date(laterDateTime));
                                } else {
                                    alert("Later time is not right");
                                    return {data: {reload: window.location.reload()}}
                                }
                            }
                            delete params.data['isLaterAndDate'];
                        }

                        // Object.keys(params.data).map(key=>resObj.set(key, params.data[key]));
                        try {
                            const r = await resObj.save(params.data);
                            return {data: {id: r.id, ...r.attributes, reload: window.location.reload()}}
                        } catch (error) {
                            return error;
                        }
                        break;

                    case "travel":

                        break;

                    default:
                        break;
                }


            }
            case UPDATE: {
                try {
                    const obj = await query.get(params.id);
                    const keys = Object.keys(params.data).filter(o => o == "id" || o == "createdAt" || o == "updatedAt" ? false : true);
                    const data = keys.reduce((r, f, i) => {
                        r[f] = params.data[f];
                        return r;
                    }, {});
                    // console.log(obj);
                    const r = await obj.save(data);
                    // console.log(r);
                    // console.log({data: {id: r.id, ...r.attributes}});
                    return {data: {id: r.id, ...r.attributes}}
                } catch (error) {
                    throw Error(error.toString());
                }
            }
            case UPDATE_MANY: {
                try {
                    const qs = await Promise.all(params.ids.map(id => (new Parse.Query(resourceObj)).get(id)));
                    qs.map(q => q.save(params.data));
                    return {data: params.ids}
                } catch {
                    throw Error("Failed to update all");
                }
            }
            case DELETE: {
                try {
                    const obj = await query.get(params.id);
                    const data = {data: {id: obj.id, ...obj.attributes}}
                    await obj.destroy();
                    return data;
                } catch (error) {
                    throw Error("Unable to delete");
                }
            }
            case DELETE_MANY: {
                try {
                    const qs = await Promise.all(params.ids.map(id => (new Parse.Query(resourceObj)).get(id)));
                    await Promise.all(qs.map(obj => obj.destroy()));
                    return {data: params.ids}
                } catch (error) {
                    throw Error("Unable to delete all");
                }

            }
        }
    };
}

export const ParseClient = parseC;