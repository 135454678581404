import * as React from 'react';
import AppBar from '@material-ui/core/AppBar';
import {Fragment} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {useLogout, useLocale, useSetLocale} from 'react-admin';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import Slide from '@material-ui/core/Slide';
import PropTypes from 'prop-types';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Parse from "parse";
import './/Style/header.css'
import {useTranslate} from 'react-admin'
import {NavLink,} from "react-router-dom";
import {slide as Menu} from 'react-burger-menu'


import './App.css'
import {ActiveTravelsList, TravelsList} from "./Travel/Travels";


function HideOnScroll(props) {
    const {children, window} = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({target: window ? window() : undefined});

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

const OrderAppBar = (props) => {
    const translate = useTranslate();
    const setLocale = useSetLocale();
    const logout = useLogout();
    const handleLogOut = () => logout();
    const locale = useLocale();

    return (
        <HideOnScroll {...props}>
            <AppBar {...props} >

                <div id='header' className="header">

                    {/* <img src={(Parse.User.current())?(Parse.User.current().get("companyLogo")) ? Parse.User.current().get("companyLogo")._url : "": ""} alt="Moyn logo" style={{ height: "70px", width: "230px", float: "right", paddingRight: "100px" }} /> */}
                    <div className='header__logo'><img className='logo__img' src="assets/images/moyn_logo.jpeg"
                                                       alt="Moyn logo"/></div>
                    <div className='header__menu'>
                        {/*<div className="menu__p">*/}
                        {/*    <NavLink style={{color:"black"}} to="/activeTravels">*/}
                        {/*        <p className="p1"> Active Travels </p>*/}
                        {/*    </NavLink>*/}
                        {/*    <NavLink style={{color:"black"}} to="/travel">*/}
                        {/*        <p className="p"> Travel History</p>*/}
                        {/*    </NavLink>*/}
                        {/*</div>*/}


                        <div className="menu__icons">
                            {/*<NavLink style={{color:"black"}} to="/activeTravels">*/}
                            {/*    <i style={{color:"#606060"}} className="fa-solid fa-compass"></i>*/}
                            {/*</NavLink>*/}
                            {/*<NavLink style={{color:"black"}} to="/travel">*/}
                            {/*    <i style={{color:"#606060"}}   className="fa-regular fa-clock"></i>*/}
                            {/*</NavLink>*/}
                        </div>

                    </div>
                    <div className='header__icons'>
                        <div className='icons__flag'>

                            <img className='flag__ge' src="assets/images/flagGe.png"
                                 class={(locale === "ge") ? "languageFlag active-language" : "languageFlag"}
                                 onClick={() => {
                                     setLocale('ge');
                                     window.location.reload();
                                     localStorage.setItem('locale', "ge");
                                 }}/>
                            <img src="assets/images/flagUSA.png"
                                 class={(locale === "en") ? "languageFlag active-language" : "languageFlag"}
                                 onClick={() => {
                                     setLocale('en');
                                     window.location.reload();
                                     localStorage.setItem('locale', "en");
                                 }}/>
                        </div>

                        <div className='icons__logout' onClick={handleLogOut}>
                            <PowerSettingsNewIcon className='logout__icon'/>
                            <p className='logout__text'>{translate("ra.moynTexts.logOut")}</p>


                        </div>


                    </div>


                </div>
                <div id="burger__menu" className="burger__menu">

                    <Menu {...props}>
                        <div className='icons__flag'>

                            <img className='flag__ge' src="assets/images/flagGe.png"
                                 className={(locale === "ge") ? "languageFlag active-language" : "languageFlag"}
                                 onClick={() => {
                                     setLocale('ge');
                                     window.location.reload();
                                     localStorage.setItem('locale', "ge");
                                 }}/>
                            <img src="assets/images/flagUSA.png"
                                 className={(locale === "en") ? "languageFlag active-language" : "languageFlag"}
                                 onClick={() => {
                                     setLocale('en');
                                     window.location.reload();
                                     localStorage.setItem('locale', "en");
                                 }}/>
                        </div>
                        <NavLink style={{color: "black"}} to="/activeTravels">

                            <i style={{color: "#606060"}} className="fa-solid fa-compass"></i> Active Travels
                        </NavLink>
                        <NavLink style={{color: "black"}} to="/travel">
                            <i style={{color: "#606060"}} className="fa-regular fa-clock"></i> Travel History
                        </NavLink>

                        <div className='icons__logout' onClick={handleLogOut}>
                            <i style={{color: "#606060", fontSize: "23px", paddingRight: "7px"}}
                               className="fa-solid fa-power-off"></i>
                            <span className='logout__text'>{translate("ra.moynTexts.logOut")} </span>
                        </div>


                    </Menu>


                    {/*<Sidebar1 props={props} pageWrapId={"header"} outerContainerId={"burger__menu"} />*/}
                    {/*<NavLink style={{color:"black"}} to="/activeTravels">*/}
                    {/*    <p className="p1"> Active Travels </p>*/}
                    {/*</NavLink>*/}
                    {/*<NavLink style={{color:"black"}} to="/travel">*/}
                    {/*    <p className="p"> Travel History</p>*/}
                    {/*</NavLink>*/}

                </div>
            </AppBar>
        </HideOnScroll>
    );
}

/* const OrderAppBar = props => (

    <AppBar {...props} >
        <div style={{ background: "black" }}>
            <img src="assets/images/moyn_logo.png" alt="Moyn logo" style={{ height: "70px", width: "500px" }} class="center" />
            <a onClick={handleLogOut} class="channel-logout">logout</a>
        </div>
    </AppBar>
); */

export default OrderAppBar;

