import * as React from "react";
import {
    List,
    CardActions,
    Datagrid,
    TextField,
    ReferenceField,
    DateField,
    BooleanField,
    useLocale,
    Create,
    useLogout,
    useSetLocale
} from 'react-admin';
import MoynTextField from "./MoynTextField";
import {useTranslate} from 'ra-core';
import ListMapField from "./ListMapField";
import {TravelCreateForm} from './TravelCreateForm'
import StatusField from './StatusField'
/* import SocketField from "./SocketField"; */
import {Link} from 'react-router-dom';
import Parse from "parse";
import SocketField from "./SocketField";
import DelayedMapField from "./DelayedMapField";
import DriverInfoField from "./DriverInfoField";
import "../Style/main.css"
import {Route, Routes} from "react-router-dom";
import ArrivalTimeField from "./ArrivalTimeField";


export const TravelsList = (props) => (
    <List {...props} exporter={false}>
        <TravelsGrid/>
    </List>
);


export const ActiveTravelsList = (props) => (
    <List {...props} exporter={false} /* filters={<TravelsFilter />} */>
        <ActiveTravelsGrid/>
    </List>
);

export const TravelsCreate = (props) => (
    <Create   {...props}>
        <TravelCreateForm/>
    </Create>
);

export const ActiveTravelsGrid = ({ids, data, selectedIds, basePath}) => {

    const locale = useLocale();
    const translate = useTranslate();

    const travelRowStyle = (record, index) => {
        if (record.status) {
            switch (record.status.id) {
                case "nIIDesE9Fw":

                    break;

                case "KzuuMNyMTq":

                    break;

                case "Do7paui8za":

                    break;

                case "OlXfRKUflr":
                    return {backgroundColor: 'blue'}
                    break;

                case "7jSeuVPShE":

                    break;

                case "UgmNTSBTyn":

                    break;

                case "vcG6BBT3C5":

                    break;

                case "qjX0NcHGJf":

                    break;

                case "uRfrIgdSLW":

                    break;

                case "YF2Alb9IPF":

                    break;

                default:
                    break;
            }
            return {backgroundColor: 'white'}
        } else {
            return {backgroundColor: 'white'}
        }

    };


    return (

        <div className="order">

            <div className="order__main">

                <Route path="activeTravels/" element={<ActiveTravelsList/>}/>
                <Route path="/travel" element={<TravelsList/>}/>
                <Create basePath={basePath} resource="">
                    <TravelCreateForm/>
                </Create>
                {/*   <div class="workspace">
                {ids.map((id, index) => {
                    return ( */}


            </div>


            <div className="order__row">
                <DelayedMapField/>
            </div>
            {/*       )
                })}
            </div> */}

            <Datagrid rowStyle={travelRowStyle}>

                {/*<MoynTextField source="request_car_type" fieldName="model"/>*/}
                <TextField source="pickup_address"/>
                <TextField source="destination_address"/>

                {/* <BooleanField source="isLater" /> */}
                {/* <DateField source="request_time" showTime /> */}
                {/* <DateField source="start_timestamp" showTime />
                    <DateField source="finish_timestamp" showTime /> */}
                <DriverInfoField source="driver_id"/>
                {/*           <ArrivalTimeField source="arrivalTime" /> */}
                <StatusField source="status" fieldName="statusDesc"/>
            </Datagrid>
        </div>

    )
};


export const TravelsGrid = ({ids, data, selectedIds, basePath}) => {

    const locale = useLocale();
    const translate = useTranslate();


    return (
        <div>
            {/*      <Create basePath={basePath} resource="Company">
                <TravelCreateForm />
            </Create> */}
            {/*   <div class="workspace">
                {ids.map((id, index) => {
                    return ( */}

            {/*  <ListMapField source="ggMap" /> */}

            <Datagrid>
                <TextField source="id"/>
                <MoynTextField source="request_car_type" fieldName="model"/>
                <MoynTextField source="status" fieldName="statusDesc"/>
                <TextField source="pickup_address"/>
                <TextField source="destination_address"/>
                <TextField source="cost"  /* label="Cost" */ />
                <BooleanField source="isLater"/>
                <BooleanField source="online_payment"/>
                <BooleanField source="isOtherPayment"/>
                <DateField source="request_time" showTime/>
                <DateField source="start_timestamp" showTime/>
                <DateField source="finish_timestamp" showTime/>
                <MoynTextField source="driver_id" fieldName="first_name"/>
            </Datagrid>
            {/*       )
                })}
            </div> */}
        </div>

    )
};


const TravelsFilter = (props) => {
    const locale = useLocale();
    const translate = useTranslate();
    const logout = useLogout();
    const handleLogOut = () => logout();
    const setLocale = useSetLocale();


    return (
        <div>
            <h1>headerrr</h1>
            <a onClick={handleLogOut} class="channel-logout">logout</a>
        </div>
    )
};