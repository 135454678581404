import React, {Component} from "react";
import {addField} from 'ra-core';
import TextField from '@mui/material/TextField';
import {getArrivalTime} from "./api";
import SearchBox from './SearchBox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Parse from "parse";

class MoynText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ""
        };

        this.handleChange = (event) => {

            this.setState({text: event.target.value}, () => {
                const {input} = this.props;
                return input.onChange(this.state.text);
            });
        }


    }

    componentDidMount() {

    }

    render() {
        // const { translate } = this.props;
        //console.log("getArrT", getArrivalTime("es 1l param@"))
        return (
            <div className="First-Search">
                <TextField id="standard-basic" label={this.props.text} variant="standard" value={this.state.text} onChange={this.handleChange}/>
            </div>
        );
    }
}

export const MoynTextInput = addField(MoynText);