import React, {Component} from 'react';
import {addField} from 'ra-core';
import {withTranslate} from "react-admin";
import {getDriversFromSocket, socket} from './api';
import GoogleMapReact from 'google-map-react';
import Parse from "parse";
import SvgIcon from '@material-ui/core/SvgIcon';


const FreeBusyMarker = ((props) => {
        const {status, show, freeMarkerInfo, busyMarkerInfo} = props

        switch (status) {
            case "free":
                return (
                    <>
                        <div>
                            <img
                                src="assets/images/free_taxi_marker.png" alt='busy Driver Image' height="20px"/>
                        </div>
                        {/* Below is info window component */}
                        {show && freeMarkerInfo
                            /*      (
                                   <div>
                                     <table style={{ background: "white", width: "320px" }}>
                                       <tr>
                                         <td rowSpan="2" align="center">
                                           <img
                                             src={props.driver.get("image").get("address")._url}
                                             alt='Busy Driver Image' width="70" height="70" style={{ borderRadius: "50%" }} />
                                         </td>
                                         <td>ID</td>
                                         <td>{props.driver.id}</td>
                                       </tr>
                                       <tr>
                                         <td>Mobile number</td>
                                         <td>{props.driver.get("phone_number")}</td>
                                       </tr>
                                       <tr>
                                         <td align="center" style={{ fontWeight: "bold" }}>{props.driver.get("first_name") + " " + props.driver.get("last_name")}</td>
                                         <td>Taxi number</td>
                                         <td>{props.driver.get("car_id").get("taxiNumber")}</td>
                                       </tr>
                                       <tr>
                                         <td></td>
                                         <td>Car number</td>
                                         <td>{props.driver.get("car_id").get("carNumber")}</td>
                                       </tr>
                                       <tr>
                                         <td></td>
                                         <td>Seats count</td>
                                         <td>{props.driver.get("car_id").get("taxi_type").get("seatsCount")}</td>
                                       </tr>
                                     </table>

                                   </div>
                                 ) */
                        }
                    </>
                )
                break;

            case "busy":

                return (
                    <>
                        <div>
                            <img
                                src="assets/images/busy_taxi_marker.png" alt='busy Driver Image' height="20px"/>
                        </div>
                        {/* Below is info window component */}
                        {show && busyMarkerInfo/* (
            <div>
              <table style={{ background: "white", width: "320px" }}>
                <tr>
                  <td rowSpan="2" align="center">
                    <img
                      src={props.driver.get("image").get("address")._url}
                      alt='Busy Driver Image' width="70" height="70" style={{ borderRadius: "50%" }} />
                  </td>
                  <td>ID</td>
                  <td>{props.driver.id}</td>
                </tr>
                <tr>
                  <td>Mobile number</td>
                  <td>{props.driver.get("phone_number")}</td>
                </tr>
                <tr>
                  <td align="center" style={{ fontWeight: "bold" }}>{props.driver.get("first_name") + " " + props.driver.get("last_name")}</td>
                  <td>Taxi number</td>
                  <td>{props.driver.get("car_id").get("taxiNumber")}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Car number</td>
                  <td>{props.driver.get("car_id").get("carNumber")}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Seats count</td>
                  <td>{props.driver.get("car_id").get("taxi_type").get("seatsCount")}</td>
                </tr>
              </table>

            </div>
          ) */}
                    </>
                )
                break;

            default:
                break;
        }


    }
)


class ListMapField extends Component {
    static defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 11
    };

    constructor(props) {
        super(props);
        this.state = {
            drivers: [],
            driversIds: [],
            show: false,
            geopoint: {lng: 12.1035593, lat: 54.1293711},
            markers: [
                /*     { lng: 44.515490, lat: 40.179200, showInfoWindow: false, driverId: "" },
                    { lng: 44.500490, lat: 40.177200, showInfoWindow: false, driverId: "" } */
            ],
            freeMarkers: [],
            busyMarkers: [],
            freeMarkerInfo: ''
        }

        this.makeTimer();


        this.handleChange = (event) => {
            console.log("handle change")
        }


    }

    makeTimer() {
        setInterval(async () => {
            var lng = 44.503490;
            var lat = 40.177200;

            socket.emit("getDriversLocationByStatus", lat, lng, "free", (answercode, exist, locations) => {
                var freeMarkers = [];
                locations.forEach(location => {
                    freeMarkers.push({lng: location.lng, lat: location.lat, showInfoWindow: false, driverId: location.key.split("__")[0]})
                });
                this.setState({freeMarkers: freeMarkers})
            });
            socket.emit("getDriversLocationByStatus", lat, lng, "busy", (answercode, exist, locations) => {
                var busyMarkers = [];
                locations.forEach(location => {
                    busyMarkers.push({lng: location.lng, lat: location.lat, showInfoWindow: false, driverId: location.key.split("__")[0]})
                });
                this.setState({busyMarkers: busyMarkers})
            });
        }, 7000)
    }


    componentDidMount() {
        const currentUserGeo = Parse.User.current().get('defaultTravelPickupGeo') || undefined;
        if (currentUserGeo) {
            this.setState({geopoint: {lat: currentUserGeo.latitude, lng: currentUserGeo.longitude}})
        }
        const resourceObj = Parse.Object.extend("User");
        const query = new Parse.Query(resourceObj);
        /* query.equalTo("rider_id", { "__type": "Pointer", "className": "_User", "objectId": Parse.User.current().id }); */
        /* query.equalTo("status", { "__type": "Pointer", "className": "travel_statuses", "objectId": "7jSeuVPShE" }); *///7jSeuVPShE-travel_started
        query.include("car_id");
        query.include("car_id.taxi_type");
        query.include("image.address");
        query.equalTo("is_driver_registered", true)
        query.find().then((resultDrivers) => {
            var drivers = {};
            resultDrivers.forEach(resultDriver => {
                drivers[resultDriver.id] = resultDriver
            });
            this.setState({drivers: drivers});
        });
    }

    _onChildClick = (key, childProps) => {
        switch (childProps.status) {
            case "free":
                const resourceObj = Parse.Object.extend("User");
                const query = new Parse.Query(resourceObj);
                /* query.equalTo("rider_id", { "__type": "Pointer", "className": "_User", "objectId": Parse.User.current().id }); */
                /* query.equalTo("status", { "__type": "Pointer", "className": "travel_statuses", "objectId": "7jSeuVPShE" }); *///7jSeuVPShE-travel_started
                query.equalTo("objectId", childProps.driverId)
                query.include("car_id");
                query.include("car_id.taxi_type");
                query.include("image.address");
                query.equalTo("is_driver_registered", true)
                query.first().then((driver) => {


                    let freeMarkerInfo = <div>
                        <table style={{background: "white", width: "320px"}}>
                            <tr>
                                <td rowSpan="2" align="center">
                                    <img
                                        src={driver.get("image").get("address")._url}
                                        alt='Busy Driver Image' width="70" height="70" style={{borderRadius: "50%"}}/>
                                </td>
                                <td>ID</td>
                                <td>{driver.id}</td>
                            </tr>
                            <tr>
                                <td>Mobile number</td>
                                <td>{driver.get("phone_number")}</td>
                            </tr>
                            <tr>
                                <td align="center" style={{fontWeight: "bold"}}>{driver.get("first_name") + " " + driver.get("last_name")}</td>
                                <td>Taxi number</td>
                                <td>{driver.get("car_id").get("taxiNumber")}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Car number</td>
                                <td>{driver.get("car_id").get("carNumber")}</td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>Seats count</td>
                                <td>{driver.get("car_id").get("taxi_type").get("seatsCount")}</td>
                            </tr>
                        </table>

                    </div>

                    try {
                        var freeMarkers = this.state.freeMarkers;
                        freeMarkers.forEach(freeMarker => {
                            if (freeMarker.driverId === childProps.driverId) {
                                freeMarker.show = true;
                            } else {
                                freeMarker.show = false
                            }
                        });
                        this.setState({freeMarkers: freeMarkers, freeMarkerInfo: freeMarkerInfo})
                    } catch (error) {

                    }
                });
                break;

            case "busy":

                try {


                    var travel_StatusesModel = Parse.Object.extend('travel_statuses');
                    var currentTravelQA = new travel_StatusesModel({objectId: "qjX0NcHGJf", mode: "driver_accepted"});
                    var currentTravelQS = new travel_StatusesModel({objectId: "7jSeuVPShE", mode: "travel_started"});
                    const BDIQConAccepted = new Parse.Query(Parse.Object.extend("travel"));
                    BDIQConAccepted.equalTo('status', currentTravelQA);
                    const BDIQConStarted = new Parse.Query(Parse.Object.extend("travel"));
                    BDIQConStarted.equalTo('status', currentTravelQS);
                    const busyDriverInfoQuery = new Parse.Query.or(BDIQConAccepted, BDIQConStarted);
                    busyDriverInfoQuery.include("driver_id");
                    busyDriverInfoQuery.include("driver_id.car_id");
                    busyDriverInfoQuery.include("driver_id.image");
                    var UserModel = Parse.User;
                    var currentDriver = new UserModel({objectId: childProps.driverId});
                    busyDriverInfoQuery.equalTo('driver_id', currentDriver);
                    busyDriverInfoQuery.first().then((resultsBusy) => {
                        var busyMarkers = this.state.busyMarkers;
                        busyMarkers.forEach(busyMarker => {
                            if (busyMarker.driverId === childProps.driverId) {
                                busyMarker.show = true;
                            } else {
                                busyMarker.show = false
                            }
                        });


                        let busyMarkerInfo = '';

                        busyMarkerInfo = <div>
                            <table style={{background: "white", width: "320px"}}>
                                <tbody>
                                <tr>
                                    <td rowspan="6" align="center"><img
                                        src={resultsBusy.get("driver_id").get("image").get("address")._url}
                                        alt="Busy Driver Image" width="70" height="70" style={{borderRadius: '50%'}}/>
                                    </td>
                                    <td>ID</td>
                                    <td>{resultsBusy.get('driver_id').id}</td>
                                </tr>
                                <tr>
                                    <td>Mobile number</td>
                                    <td>{resultsBusy.get("driver_id").get("phone_number")}</td>
                                </tr>
                                <tr>
                                    <td>Taxi number</td>
                                    <td>{resultsBusy.get("driver_id").get("car_id").get("taxiNumber")}</td>
                                </tr>
                                <tr>
                                    <td>Travel ID</td>
                                    <td>{resultsBusy.id}</td>
                                </tr>
                                <tr>
                                    <td>Start timestamp</td>
                                    <td>{(resultsBusy.get("start_timestamp")) ? resultsBusy.get("start_timestamp").getFullYear() + '-' + ('0' + (resultsBusy.get("start_timestamp").getMonth() + 1)).slice(-2) + '-' + ('0' + resultsBusy.get("start_timestamp").getDate()).slice(-2) : 'N/A'}</td>
                                </tr>
                                <tr>
                                    <td>Pickup address</td>
                                    <td>{resultsBusy.get("pickup_address")}</td>
                                </tr>
                                <tr>
                                    <td align="center" style={{fontWeight: 'bold'}}>{resultsBusy.get("driver_id").get("first_name") + " " + resultsBusy.get("driver_id").get("last_name")}</td>
                                    <td>Destination address</td>
                                    <td>{(resultsBusy.get("destination_address")) ? resultsBusy.get("destination_address") : 'N/A'}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>


                        this.setState({busyMarkers: busyMarkers, busyMarkerInfo: busyMarkerInfo})
                    });


                } catch (error) {

                }
                break;

            default:
                break;
        }

    }


    render() {
        const {translate} = this.props;


        return (
            <div>

                <div style={{height: '70.5vh', width: '100%'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: "AIzaSyAMUQvflhiCHNmXfZi36Ckm2eYUpdsodCg"}}
                        defaultCenter={this.state.geopoint}
                        defaultZoom={11}
                        onChildClick={this._onChildClick}
                    >
                        {(this.state.freeMarkers) ? this.state.freeMarkers.length > 0 && this.state.freeMarkers.map((marker, index) => {
                            return (
                                <FreeBusyMarker
                                    key={'free' + index + 1}
                                    lat={marker.lat}
                                    lng={marker.lng}
                                    show={marker.show}
                                    driverId={marker.driverId}
                                    status="free"
                                    freeMarkerInfo={this.state.freeMarkerInfo}
                                />
                            )
                        }) : ""}
                        {(this.state.busyMarkers) ? this.state.busyMarkers.length > 0 && this.state.busyMarkers.map((marker, index) => {
                            return (
                                <FreeBusyMarker
                                    key={'busy' + index + 1}
                                    lat={marker.lat}
                                    lng={marker.lng}
                                    show={marker.show}
                                    driverId={marker.driverId}
                                    status="busy"
                                    busyMarkerInfo={this.state.busyMarkerInfo}
                                />
                            )
                        }) : ""}
                    </GoogleMapReact>
                </div>

            </div>
        )
    }
}

export default ListMapField;



