import React, {Component} from "react";
import {addField} from 'ra-core';
import {withTranslate} from 'react-admin';
import FormControlLabel from '@material-ui/core/FormControlLabel';


import "flatpickr/dist/themes/material_green.css";

import Flatpickr from "react-flatpickr";

import Checkbox from '@material-ui/core/Checkbox';

class IsLaterAndDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLater: false,
            laterDateTime: new Date(),
        };

        this.handleIsLaterChange = (event) => {
            this.setState({isLater: event.target.checked}, () => {
                const {input} = this.props;
                return input.onChange(this.state);
            });
        }

        this.handleDateTimeChange = () => {

        }

    }

    componentDidMount() {


    }

    render() {
        const {translate} = this.props;
        return (
            <div style={{marginTop: "10px"}}>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={this.state.isLater}
                            onChange={this.handleIsLaterChange}
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    }
                    label={translate('resources.travel.fields.isLater')}
                />

                {this.state.isLater && <Flatpickr
                    data-enable-time
                    value={this.state.laterDateTime}
                    options={{minDate: new Date()}}
                    onChange={date => {
                        this.setState({laterDateTime: Date.parse(date)}, () => {
                            const {input} = this.props;
                            return input.onChange(this.state);
                        });
                    }}
                />}
            </div>
        );
    }
}

export const IsLaterAndDateInput = withTranslate(addField(IsLaterAndDate));