import React, {Component} from "react";
//import {withTranslate  } from "react-admin";
//import { addField } from 'ra-core';
import ProgressBar from './ProgressBar'
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import {socket} from './api';

import Parse from "parse";


class StatusField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            percent: 1,
            statusText: "",
            showCancelButton: false,
            showProgressBar: false,
        };

        this.makeTimer();


        this.handleCancel = async (event) => {

            document.getElementById("popup_box").innerHTML = '<div>' +
                '<h1>Are you sure you want to cancel this order?</h1>' +
                '<div class="btns">' +
                '<button onclick="cancelOrder(\'' + this.props.record.id + '\')" class="btn2">Yes</button>' +
                '<a href="." class="btn1">No</a>' +
                '</div>' +
                '</div>'
            document.getElementById("popup_box").style.display = "block";
            /*  const confirm = window.confirm('Are you sure you want to cancel this order?');
             if (confirm) {
                 const resourceObj = Parse.Object.extend("travel");
                 const query = new Parse.Query(resourceObj);
                 const obj = await query.get(this.props.record.id);
                 obj.set("status", { "__type": "Pointer", "className": "travel_statuses", "objectId": "UgmNTSBTyn" })
 
                 const r = await obj.save();
                 window.location.reload();
 
                 //  console.log('Order was canceled.');
             } else {
                 // Do nothing!
                 // console.log('Order was NOT canceled.');
             }
  */
        }


    }


    makeTimer() {
        setInterval(() => {
            if (this.state.percent === 1) {
                this.setState({percent: 0})
            } else {
                this.setState({percent: 1})
            }

        }, 1500)
    }


    componentDidMount() {

        try {
            //  this.props.record[this.props.source]

            switch (this.props.record[this.props.source].id) {
                case "YF2Alb9IPF"://Requested
                    this.setState({showCancelButton: true, showProgressBar: true});
                    break;

                case "KzuuMNyMTq"://Requested for later
                    this.setState({showCancelButton: true});
                    break;

                case "qjX0NcHGJf"://Driver accepted
                    this.setState({showCancelButton: true});
                    break;

                case "nIIDesE9Fw"://Driver accepted for later
                    this.setState({showCancelButton: true});
                    break;

                default:
                    break;
            }

            this.setState({statusText: this.props.record[this.props.source].get("statusDesc")});
        } catch {

        }
    }

    render() {
        // const { translate } = this.props;
        return (
            <div>
                <span style={{marginLeft: "10px", marginTop: "3px"}}>{this.state.statusText}</span>
                {this.state.showCancelButton &&
                <div style={{marginBottom: "5px"}}>
                    <Button variant="contained" color="secondary" startIcon={<CloseIcon/>} onClick={this.handleCancel}>Cancel </Button>
                </div>
                }
                {this.state.showProgressBar &&
                <ProgressBar width={115} percent={this.state.percent}/>
                }
            </div>
        );
    }
}


export default StatusField;
