import {RecordContext} from "ra-core";
import React, {Component} from "react";
//import {withTranslate  } from "react-admin";
//import { addField } from 'ra-core';

class DriverInfoField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ""
        };

        this.handleChange = (event) => {

        }
    }

    componentDidMount() {


        /*     try {
                if (this.props.record[this.props.source]) {
                    var text = this.props.record[this.props.source].get(this.props.fieldName);
                    this.setState({ text: text });
                }
                else {
    
                }
            }
            catch { } */


    }

    render() {
        // const { translate } = this.props;
        return (
            <span
                class="MuiTypography-root MuiTypography-body2"> {(this.props.record.driver_id) ? this.props.record.driver_id.attributes.first_name + " " + this.props.record.driver_id.attributes.last_name + " " + this.props.record.driver_id.attributes.phone_number : ""}</span>
        );
    }
}

export default DriverInfoField;
