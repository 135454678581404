import React from 'react';
import {FormWithRedirect, useTranslate, SaveButton, required} from 'react-admin';
import {CarTypeInput} from './CarTypeInput';
import {AddressInput} from './AddressInput';
import {MoynTextInput} from './MoynTextInput';
import {IsLaterAndDateInput} from './IsLaterAndDateInput';
import ListMapField from "./ListMapField";
import '../Style/main.css'
import {ActiveDriversInput} from './ActiveDriversInput';

export const TravelCreateForm = props => {
    const translate = useTranslate();
    const locale = localStorage.getItem('locale');
    var amStyle = {};
    var ruStyle = {};
    var enStyle = {};

    switch (locale) {
        case "am":
            ruStyle = {order: 1};
            enStyle = {order: 2};
            break
        case "ru":
            amStyle = {order: 1};
            enStyle = {order: 2};
            break
        case "en":
            ruStyle = {order: 2};
            amStyle = {order: 1};
            break
    }

    return (
        <FormWithRedirect className='formredirect'
                          {...props}
                          render={formProps => (

                              <div className='main'>

                                  {/* <h3 className='main__htext'>{translate("ra.moynTexts.orderCreate")}</h3> */}


                                  {/* todo fix address and geopoint after select and remove */}
                                  <div className='main__pickup'>
                                      <AddressInput source="pickupPoint" text={translate('resources.travel.fields.pickup_address') + "*"} addressType="pickup" validate={validateCreateAddressInput}/></div>


                                  <div className='main__destination'>
                                      <AddressInput source="destinationPoint" text={translate('resources.travel.fields.destination_address')} addressType="destination"/></div>


                                  <div className='main__message'>
                                      <MoynTextInput source="orderMessage" text={translate('resources.travel.fields.orderMessage')} addressType="message"/></div>

                                  <div className='First-Search'>

                                      <ActiveDriversInput source="selectDriver"/>
                                  </div>
                                  <div className='First-Search'>
                                      <CarTypeInput className="cartype" source="carType"/>


                                      <IsLaterAndDateInput className="islater" source="isLaterAndDate"/>


                                  </div>

                                  <div className='main__button'>

                                      <SaveButton className='button__save'
                                                  color="secondary"
                                                  saving={formProps.saving}
                                                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}/>


                                  </div>


                              </div>

                          )}
        />
    )
}


const validateCreateAddressInput = (value, allValues) => {
    const errors = {};
    if (value) {
        /*    if (!value.secondLevel) {
               errors.ListCreateProfileInput = ['The field is required'];
           } */
    } else {
        errors.ListCreateProfileInput = ['The field is required'];
    }
    return errors
}


