import {RecordContext} from "ra-core";
import React, {Component} from "react";
import {getArrivalTime} from "./api";
import {GoogleMap, DistanceMatrixService, useJsApiLoader} from "@react-google-maps/api";
import GoogleMapReact from 'google-map-react';
//import {withTranslate  } from "react-admin";
//import { addField } from 'ra-core';


const containerStyle = {
    width: '400px',
    height: '400px'
};

const center = {
    lat: -3.745,
    lng: -38.523
};

function MyComponent() {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyAMUQvflhiCHNmXfZi36Ckm2eYUpdsodCg"
    })

    const [map, setMap] = React.useState(null)

    const onLoad = React.useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds();
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            <DistanceMatrixService
                options={{
                    destinations: [{lat: 1.296788, lng: 103.778961}],
                    origins: [{lng: 103.780267, lat: 1.291692}],
                    travelMode: "DRIVING",
                }}
                callback={(response) => {
                    console.log(response)
                }}
            />
        </GoogleMap>
    ) : <></>
}

class ArrivalTimeField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ""
        };

        this.handleChange = (event) => {

        }
    }

    componentDidMount() {


        /*     try {
                if (this.props.record[this.props.source]) {
                    var text = this.props.record[this.props.source].get(this.props.fieldName);
                    this.setState({ text: text });
                }
                else {
    
                }
            }
            catch { } */


    }

    render() {
        //console.log("getArrT", getArrivalTime("es 1l param@"))
        // const { translate } = this.props;
        return (
            <MyComponent/>
        );
    }
}

export default ArrivalTimeField;
