import React, {useState, useEffect} from "react";
import io from "socket.io-client";
import Parse from "parse";
import ListMapField from "./ListMapField";
import ReactDelayRender from 'react-delay-render';

const DelayedMapField = () => (
    <div className="row">
        <ListMapField/>
    </div>
);

export default ReactDelayRender({delay: 500})(DelayedMapField);